import { useRef, useEffect, useState } from "react";
import styles from "./mirrorScreenSever.module.css";
import Lottie from 'react-lottie';
import LOTIE from "../../data/lottie";

function MirrorScreenSever(props){

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LOTIE.lottieJsonTap,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


    const [screenSever, setScreenSever] = useState(true);
    const [startNewSession, setStartNewSession] = useState(false)
    const [sessionClass, setSessionClass] = useState(styles['start-new-session-entered']);
    
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const videos = [
      // 'https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FVIDEO_C_Vertical.mov?alt=media&token=0520888a-0da1-4e2d-878e-706f8582a2ac',
      // 'https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FVIDEO_A_Vertical.mov?alt=media&token=22085d7b-cf4b-4dff-943b-c9f435b47fcc',
      // 'https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2F0001-0130_vert.mp4?alt=media&token=581d9a00-1675-465d-a4f6-92639fdfebe4',
      'https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCARDUC005S_VERT.mp4?alt=media&token=18c04d4c-f1e4-435d-9299-90b7bda4efb0',
      'https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCARRERA315S_VERT.mp4?alt=media&token=7fe701c9-ae7d-4c95-861d-c5095e059880',
      'https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCARRERA316S_VERT.mp4?alt=media&token=7f83cb56-ab2e-489f-8b10-0dac41906412',
      'https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2Fcarduc005s_vert.mp4?alt=media&token=dd4f270f-c535-4d78-a7a3-0e47a04d8d0e',
      'https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FPLD2151S_sara_VERT.mp4?alt=media&token=e3c65d06-a3ed-4800-b401-a8ba8f825641'
    ];

    const videoRef = useRef(null);

    const handleVideoEnd = () => {
        console.log("Ended",(prevIndex) => (prevIndex + 1) % videos.length )
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
      }



    return(
    <>
    {screenSever && (
     
        <div className={styles["modal-backdrop-on-people-screen"]} onClick={()=>props.handleStartNewSession()}>

<video            
                        src={videos[currentVideoIndex]} 
                        onEnded={handleVideoEnd}
                        controls={false}
                        ref={videoRef}
                        autoPlay  
                        muted  // Modifica qui
                        // style={{
                        //   width: "100%",
                        //   height: "100%",
                        //   position: "absolute",
                        //   top: "0",
                        //   left: "0",
                        // }}
                    />
           
                    <div className={styles.lottie_container}>
                    {/* <div className={`${styles["start-new-session-entered"]} ${styles['fade-in']}`}> */}
                    <Lottie options={defaultOptions} height={200} width={200} />
                    Tap to Start

                    </div>
                  {/* </div>           */}
         
            </div>
        
      )}
      </>
    )
}

export default MirrorScreenSever;