import React, { forwardRef } from 'react';
import style from './MirrorHeader.module.css';
import { useTranslation } from 'react-i18next';

const MirrorTittle = forwardRef(({ title, subtitle, skip = true , handleSkip}, ref) => {

    const { t } = useTranslation();

    return (
        <div ref={ref} className={style['header-container']}>
            <div className={style.title}>{title}</div>
            <div className={style.subtitle}>{subtitle}</div>
            {skip && <div className={style.subtitle2}> <button onClick={handleSkip} className={style.skipBtn}>{t("or_skip_and_go_to_glass")}</button> </div>}
        </div>
    );
});

export default MirrorTittle;
