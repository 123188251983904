import { useContext, useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import style from "./IntroPage.module.css";
import MirrorScreenSever from "../component/mirror-component/mirrorScreenSever";
import CatalogueVto from "./CatalogueVto";
import GlassesCard from "../component/GlassesCard";
import { CatalogDataContext } from "../data/CatalogueDataContext";
import MirrorMenu from "../component/mirror-component/MirrorMenu";
import i18n from "i18next";
import "../Utils/LanguageUtils/i18n";
import FilterByGender from "../component/mirror-component/FilterByGender";
import FilterByShape from "../component/mirror-component/FilterByShape";
import LanguageSelector from "../component/mirror-component/LanguageSelector";
import { useTranslateText } from "../Utils/function";
import MirrorGlassesCard from "../component/mirror-component/MirrorGlassesCard";
import CardContainerTwoC from "../component/container/CardContainerTwoC";
import CardButton from "../component/mirror-component/CardButton";
import BrandsPage from "../component/mirror-component/BrandsPages";
import SingleBrandPage from "../component/mirror-component/SingleBrandPage";
import SingleGlassesPage from "../component/mirror-component/SingleGlassesPage";
import "./backStyle.scss";
import CardContainerGlasses from "../component/container/CardContainerGlasses";
import GenerateImageGlasses from "../component/mirror-component/GenerateImageController";
import VariantGlassesCard from "../component/mirror-component/VaraintGlassesCard";
import MirroVto from "./MirrorVto";

import Webcam from "react-webcam";
import faceMeshSingleton from "../Utils/FaceMeshSingleton";
import MirrorTittle from "../component/mirror-component/MirrorHeader";
import IMAGES from "../data/images";

import Footer from "../component/mirror-component/Footer";

import LoadingScreen from "../component/LoadingScreen";
import GlassesList from "../component/mirror-component/GlassesList";


function SelectionPage(props) {
  // const c = props.c;
  const translate = useTranslateText();
  const [faceMesh, setFaceMesh] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [countFrame, setCountFrame] = useState(0);
  const [hasReset, setHasReset] = useState(false);

  useEffect(() => {
    // console.log("IN HANDLE", countFrame);
    if (countFrame > 400 && !hasReset) {
      // console.log("ON COUNT");
      setStartNewSession(false);
      handleReset();
      setHasReset(true);
    }

    // Reimposta hasReset quando countFrame torna a 0
    if (countFrame === 0) {

      setHasReset(false);
    }
  }, [countFrame, hasReset]);


  const [transitionActive, setTransitionActive] = useState(false);
  const [videoNode, setVideoNode] = useState(null); // Stato per memorizzare il nodo video
  const [loadAttempted, setLoadAttempted] = useState(false); // Stato per tenere traccia se handleVideoLoad è stata chiamata
  const [openVto, setOpenVto] = useState(false);

  const [viewGlassesList, setViewGlassesList] = useState(true)
  const webcamRef = useRef();

  // Utility list for generate image
  const [allVariant, setAllVariantList] = useState(null);


  //Context Data
  const {
    catalogs,
    listOfGlasses,
    loadingProgress,

    //Function
    filterGlassesByGender,
    //Boolean
    isfilteredByGender,
    //set

    // list Tags
    listOfTags,
    // Function for get categories
    getAllFormCategories,

    //function for filtered
    filterGlassesByForm,
    //Boolean
    isFilteredByShape,
    isFilteredByType,


    filteredGlassesList,
    saveFilteredList,

    //function for set Glasses
    saveSelectedGlasses,
    //Glasses Selected
    selectedGlasses,

    //function for set Variant
    saveSelectedVariant,
    //Variant Selected
    selectedVariant,

    //function for set Brand
    saveSelectedBrand,
    //Brand Selected
    selectedBrand,

    //Utils Fanction
    getCatalogById,
    getGlassesByBrand,
    getGlassesByBrandFiltered,
    getAllVariant,

    clearSession,
    setStartNewSession,
    isNewSession,

    filterGlasses,

    //PAGE STATE
    updatePageStateInDB,
    isBrandPage,
    isSingleBrandPage,
    isSingleGlassesPage,
    startSelectionLanguage,

    selectedGender,
    selectedShape,
    selectedType,
    saveSelectedShape,
    saveSelectedGender,
    saveSelectedType,
    resetFilter,
    allShapes,
    setAllShapes,
    allType,
    setAllType

  } = useContext(CatalogDataContext);



  //Function for init Mediapipe 
  useEffect(() => {
    const faceMeshInit = faceMeshSingleton.getFaceMesh();
    setFaceMesh(faceMeshInit);
    //console.log("INIT MEDIAPIPE");
    faceMeshSingleton.onLoad((loaded) => {
      setIsLoaded(loaded);
    });
  }, []);

  //Function for notify Loading Complete
  useEffect(() => {
    if (loadingProgress === 100) {
      //console.log("Loading Complete", loadingProgress);
    }
  }, [loadingProgress]);

  //function for disable ReLoad
  // useEffect(() => {
  //   // Funzione per gestire l'evento touchmove
  //   const handleTouchMove = (event) => {
  //     // Previene il comportamento di scroll predefinito
  //     event.preventDefault();
  //   };

  //   // Aggiunge l'evento touchmove al document
  //   document.addEventListener('touchmove', handleTouchMove, { passive: false });

  //   // Rimuove l'evento quando il componente viene smontato
  //   return () => {
  //     document.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, []);

  //Notify viedeo Load
  useEffect(() => {
    if (isLoaded) {
      handleVideoLoad(videoNode);
    }
  }, [isLoaded]); // Dipendenze: isLoaded, videoNode, loadAttempted

  async function handleVideoLoad(videoNodeI) {
    if (!loadAttempted) {
      setVideoNode(videoNodeI);
      //console.log(" setto onLoad");
    }

    if (!isLoaded) {
      // console.log("NON INIZIALIZZATO");
      return; // Non fare nulla se FaceMesh non è ancora caricato
    } else {
      // console.log("INIZIALIZZATO",faceMesh,videoNode)
    }
    // console.log("IN HANDLE")
    if (videoNode) {
      // console.log("IN HANDLE 2")

      videoNode?.target.play();
      let video = videoNode?.target;
      let paintCount = 0;
      let startTime = 0.0;
      let fps = 1;
      async function detectionFrame(now, metadata) {
        // console.log("IN HANDLE 3")

        if (startTime === 0.0) {
          startTime = Date.now();
        }
        const elapsed = (Date.now() - startTime) / 1000.0;
        fps = (++paintCount / elapsed).toFixed(3);

        await faceMesh.send({ image: video });

        requestAnimationFrame(detectionFrame);
      }

      requestAnimationFrame(detectionFrame);
      let countFrame = 0;
      faceMesh.onResults((results) => {
      //   //  console.log("Results",results )
      //   //QUI LOGICA PER START NEW SESSION
      //   // if (results.multiFaceLandmarks) {
      //   //   if (results.multiFaceGeometry <= 0) {
      //   //     setCountFrame(prevCount => prevCount + 1);
      //   //   } else {
      //   //     setCountFrame(0);
      //   //   }
      //   // }
      });
    }
  }

  useEffect(() => {
    handleVideoLoad(videoNode)
  }, [videoNode])

  //Function for init BackGround
  useEffect(() => {
    const interBubble = document.querySelector(".interactive");
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;

    const move = () => {
      curX += (tgX - curX) / 20;
      curY += (tgY - curY) / 20;
      if (interBubble) {
        interBubble.style.transform = `translate(${Math.round(
          curX
        )}px, ${Math.round(curY)}px)`;
      }
      requestAnimationFrame(move);
    };

    move();

    // Pulizia: questa funzione viene chiamata quando il componente viene smontato
    return () => {
      // Qui puoi inserire qualsiasi logica di pulizia necessaria
    };
  }, []);



  //Action come Back
  const handleBack = () => {
    console.log("HANDLE BACK");
    if (openVto) {
      setOpenVto(false);
      return;
    }
    if (isSingleGlassesPage) {
      updatePageStateInDB(
        isBrandPage,
        isSingleBrandPage,
        false,
        startSelectionLanguage,
        isfilteredByGender,
        isFilteredByShape,
        isFilteredByType
      );
      saveSelectedGlasses(null);
      return;
    }
    if (isSingleBrandPage) {
      if (selectedGlasses) {
        updatePageStateInDB(
          false,
          false,
          true,
          startSelectionLanguage,
          isfilteredByGender,
          isFilteredByShape,
          isFilteredByType
        );
        let filtereListBrand = filterGlasses(selectedGender, selectedType, selectedShape, listOfGlasses);
        console.log("List", filtereListBrand, selectedGender, selectedShape)
        saveFilteredList(filtereListBrand);
      } else {
        updatePageStateInDB(
          isBrandPage,
          false,
          isSingleGlassesPage,
          startSelectionLanguage,
          isfilteredByGender,
          isFilteredByShape,
          isFilteredByType
        );

        let filtereListBrand = filterGlasses(selectedGender, selectedType, selectedShape, listOfGlasses);
        console.log("List", filtereListBrand, selectedGender, selectedShape)
        saveFilteredList(filtereListBrand);
      }
      return;
    }
    if (isBrandPage) {

      updatePageStateInDB(
        false,
        false,
        isSingleGlassesPage,
        startSelectionLanguage,
        isfilteredByGender,
        isFilteredByShape,
        isFilteredByType
      );

      saveSelectedGlasses(null)
      return;
    }
    if (startSelectionLanguage && !selectedGender && !selectedShape) {
      updatePageStateInDB(
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      return;
    }
    if (startSelectionLanguage && selectedGender && !selectedType) {
      updatePageStateInDB(
        isBrandPage,
        isSingleBrandPage,
        isSingleGlassesPage,
        startSelectionLanguage,
        false,
        false,
        false,
        false
      );
      saveSelectedGender(false);
      saveFilteredList(listOfGlasses);
    }
    if (selectedGender && selectedType && !selectedShape) {
      updatePageStateInDB(
        isBrandPage,
        isSingleBrandPage,
        isSingleGlassesPage,
        startSelectionLanguage,
        isfilteredByGender,
        false,
        false,
        false
      );
      let listFiltered = handleResetFilter(selectedGender, null, null)
      saveSelectedType(false)
      saveFilteredList(listFiltered);
    }
    // Logica per tornare indietro
    if (startSelectionLanguage && selectedGender && selectedType && selectedShape) {
      updatePageStateInDB(
        isBrandPage,
        isSingleBrandPage,
        isSingleGlassesPage,
        startSelectionLanguage,
        isfilteredByGender,
        false,
        isFilteredByType
      );
      saveSelectedShape(false);
      let listFiltered = handleResetFilter(selectedGender, selectedType, null)
      saveFilteredList(listFiltered);
    }

  };



  //Action Reset
  const handleReset = () => {
    setOpenVto(false);
    saveFilteredList(listOfGlasses);
    resetFilter();
    updatePageStateInDB(false, false, false, false, false, false, false);
    saveSelectedBrand(null);
    saveSelectedGlasses(null);
    saveSelectedVariant(null);
  };

  //Action for Filtered Tag
  const handleShapeFilter = (tagName, type) => {
    setViewGlassesList(true);
    const filteredGlasses = filterGlassesByForm(tagName, filteredGlassesList, type);
    //console.log("START FILTER BY", tagName, type, filteredGlasses)
    if (type === "Forma") {

      saveSelectedShape(tagName);
      updatePageStateInDB(
        isBrandPage,
        isSingleBrandPage,
        isSingleGlassesPage,
        startSelectionLanguage,
        isfilteredByGender,
        true,
        isFilteredByType
      );

    }
    if (type === "Tipologia") {
      const formCategories = getAllFormCategories(filteredGlasses, "Forma");
      setAllShapes(formCategories);

      saveSelectedType(tagName);
      updatePageStateInDB(
        isBrandPage,
        isSingleBrandPage,
        isSingleGlassesPage,
        startSelectionLanguage,
        isfilteredByGender,
        isFilteredByShape,
        true
      );
    }

    saveFilteredList(filteredGlasses);
    // console.log(`Occhiali filtrati per forma (${formName}):`, filteredGlasses);
  };

  //Action Reset Filter
  const handleResetFilter = (gender, type, shape) => {
    let filteredGlasses = filterGlassesByGender(gender);

    console.log("Filter List", filteredGlasses)
    const typeCategory = getAllFormCategories(filteredGlasses, "Tipologia")
    setAllType(typeCategory);
    if (type) {
      filteredGlasses = filterGlassesByForm(type, filteredGlasses, "Tipologia");
      const formCategories = getAllFormCategories(filteredGlasses, "Forma");
      setAllShapes(formCategories);
    }
    if (shape) {
      filteredGlasses = filterGlassesByForm(shape, filteredGlasses, "Forma");
    }
    saveFilteredList(filteredGlasses);
  }

  //Function for start New Session
  const handleStartNewSession = () => {
    setStartNewSession(true);
    updatePageStateInDB(false, false, false, false, false, false, false);
    saveSelectedShape(null);
    saveSelectedGender(null);
    // console.log("Start New Session");
  };

  //function for filter By Gender
  const handleFilterByGender = (gender) => {
    updatePageStateInDB(
      isBrandPage,
      isSingleBrandPage,
      isSingleGlassesPage,
      startSelectionLanguage,
      true,
      false,
      isFilteredByType
    );
    const filteredGlasses = filterGlassesByGender(gender);
    const typeCategory = getAllFormCategories(filteredGlasses, "Tipologia")
    setAllType(typeCategory);

    // const formCategories = getAllFormCategories(filteredGlasses,"Forma");
    saveSelectedGender(gender);
    saveFilteredList(filteredGlasses);
    // setAllShapes(formCategories);
    // console.log(`Filtered Glasses for ${gender}:`, filteredGlasses);
    // Puoi utilizzare filteredGlasses come necessario qui
  };


  //Action Select Language
  const handleSelectLanguage = (language) => {
    updatePageStateInDB(
      isBrandPage,
      isSingleBrandPage,
      isSingleGlassesPage,
      true,
      false,
      false,
      isFilteredByType
    );

    // UTILITY FOR IMAGE GENERATOR
    const allVariant = getAllVariant();
    setAllVariantList(allVariant);
    //console.log("Variant", allVariant);

    i18n.changeLanguage(language);
  };

  const handleSkip = () => {
    setViewGlassesList(true);
    if (!filteredGlassesList) {
      const filteredGlasses = listOfGlasses
      saveFilteredList(listOfGlasses);
    }
    console.log("HANDLE SKIP");
    //test
    updatePageStateInDB(
      isBrandPage,
      isSingleBrandPage,
      isSingleGlassesPage,
      startSelectionLanguage,
      true,
      true,
      true
    );
  }

  const handleCardClick = (glasses) => {
    // Assicuriamoci che l'oggetto glasses sia definito
    if (glasses && glasses.brandId) {
      updatePageStateInDB(
        isBrandPage,
        isSingleBrandPage,
        true,
        startSelectionLanguage,
        isfilteredByGender,
        isFilteredByShape,
        isFilteredByType
      );
      saveSelectedGlasses(glasses);

      let brand = getCatalogById(glasses.brandId);
      // Assicuriamoci che brand sia definito prima di salvarlo
      if (brand) {
        saveSelectedBrand(brand);
      }
      // navigateToProductPage(glasses.id); // Esempio di navigazione
    } else {
      console.error("L'oggetto glasses o la sua proprietà brandId non sono definiti.");
    }
  };


  //Action Open Brand Page
  const handleOpenBrandPage = () => {
    if (selectedGlasses) {
      updatePageStateInDB(
        false,
        true,
        false,
        startSelectionLanguage,
        isfilteredByGender,
        isFilteredByShape,
        isFilteredByType
      );
      let brand = getCatalogById(selectedGlasses.brandId)
      console.log("Selected Brand", brand);
      saveSelectedBrand(brand);
      // let filterListBrand = getGlassesByBrandFiltered(brand.id);
      let filteredListBrand = getGlassesByBrand(brand.id);
      console.log("LISTA FILTRATA BRAND", filteredListBrand)
      saveFilteredList(filteredListBrand);
      return;
    }

    updatePageStateInDB(
      true,
      isSingleBrandPage,
      false,
      startSelectionLanguage,
      isfilteredByGender,
      isFilteredByShape,
      isFilteredByType
    );
  };

  //Action Select Brand
  const handleSelectedBrand = (brand) => {
    updatePageStateInDB(
      isBrandPage,
      true,
      isSingleGlassesPage,
      startSelectionLanguage,
      isfilteredByGender,
      isFilteredByShape,
      isFilteredByType
    );

    console.log("Selected Brand", brand);
    saveSelectedBrand(brand);
    // let filterListBrand = getGlassesByBrandFiltered(brand.id);
    let filteredListBrand = getGlassesByBrand(brand.id);
    console.log("LISTA FILTRATA BRAND", filteredListBrand)
    saveFilteredList(filteredListBrand);
  };

  //Action Open Vto
  const handleopenVto = () => {
    setTransitionActive(true);

    setOpenVto(true);
    setTimeout(() => {
      window.location.href = "/vto"; // Cambia la pagina
    }, 500); // Imposta un timeout che corrisponde alla durata dell'animazione
  };

  const handleRandomGlasses = () => {
    if (filteredGlassesList && filteredGlassesList.length > 0) {
      // Seleziona un indice casuale dall'array
      const randomIndex = Math.floor(Math.random() * filteredGlassesList.length);
      // Ottieni l'oggetto glasses corrispondente all'indice casuale
      const randomGlasses = filteredGlassesList[randomIndex];
      // Esegui la funzione saveSelectedGlasses con l'oggetto glasses selezionato
      saveSelectedGlasses(randomGlasses);

      // console.log("Selected Random Glasses:", randomGlasses);
    } else {
      console.log("La lista degli occhiali è vuota o non definita");
    }
  }



  return (
    <>
      {(loadingProgress === 100) ? (

        <div className={style["wrapper-intro-page"]}>
          {transitionActive && <div className={style["transition-screen"]}></div>}

          <Webcam
            ref={webcamRef}
            onUserMediaError={() => console.log("Errore fotocamera")}
            onUserMedia={(stream) => console.log("Accesso fotocamera consentito")}
            onLoadedMetadata={handleVideoLoad}
            style={{
              position: "absolute",
              marginLeft: "0px",
              left: 0,
              right: 0,
              top: 0,
              transform: "scaleX(-1)",
              zIndex: 1,
              marginBottom: "0px",
              visibility: "hidden",
            }}
          ></Webcam>

          {isNewSession ? (
            <>
              <div className="gradient-bg">
                <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                  <defs>
                    <filter id="goo">
                      <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="10"
                        result="blur"
                      />
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                        result="goo"
                      />
                      <feBlend in="SourceGraphic" in2="goo" />
                    </filter>
                  </defs>
                </svg>

                <div className="gradients-container">
                  <div className="g1"></div>
                  <div className="g2"></div>
                  <div className="g3"></div>
                  <div className="g4"></div>
                  <div className="g5"></div>
                  <div className="interactive"></div>
                </div>

                <div className={style["scrool-container"]}>
                  <div className={style.header}>
                    <MirrorMenu
                      onBack={handleBack}
                      onReset={handleReset}
                      isLanguagePage={startSelectionLanguage}
                      handleOpenBrandPage={handleOpenBrandPage}
                      isOnBrandPage={isBrandPage}
                      isUnselectedFilter={startSelectionLanguage}

                    />
                  </div>

                  {startSelectionLanguage ? (
                    <>
                      {isSingleGlassesPage && selectedGlasses ? (
                        <SingleGlassesPage
                          selectedGlasses={selectedGlasses}
                          openVto={handleopenVto}
                          randomGlasses={handleRandomGlasses}
                          handleOpenBrandPage={handleOpenBrandPage}
                        />
                      ) : (
                        <>
                          {isSingleBrandPage ? (
                            <SingleBrandPage
                              selectedBrand={selectedBrand}
                              listOfGlasses={filteredGlassesList}
                              handleCardClick={handleCardClick}
                            />
                          ) : (
                            <>
                              {!isBrandPage ? (
                                <>
                                  {!isfilteredByGender ? (
                                    <>
                                      {/* -------------------------  3D IMAGE GENERATOR --------------------- */}
                                      {/* <CardContainerTwoC margin={"0px"}>

                                    {allVariant.map((variant,index) => (
                                        <VariantGlassesCard variant={variant} index={index}>

                                        </VariantGlassesCard>
                                      )
                                    )}
                                    </CardContainerTwoC>  */}

                                      {/* Genera le Glasses 3dImage */}
                                       {/* <GenerateImageGlasses glassesVariants={allVariant}></GenerateImageGlasses>   */}
                                      {/* -------------------------  3D IMAGE GENERATOR --------------------- */}

                                      <FilterByGender
                                        handleFilterByGender={
                                          handleFilterByGender
                                        }
                                        translate={translate}
                                        handleSkip={handleSkip}

                                      />
                                    </>
                                  ) : !isFilteredByType ? (
                                    <FilterByShape
                                      allShapes={allType}
                                      handleShapeFilter={handleShapeFilter}
                                      type="Tipologia"
                                      translate={translate}
                                      handleSkip={handleSkip}
                                      selectedGender={selectedGender}
                                    />
                                  ) : viewGlassesList ? (
                                    // Se togliamo 'horizontal' le card si vedono in verticale
                                    <GlassesList filteredGlassesList={filteredGlassesList} handleSelectedGlasses={handleCardClick} containerAlign={'horizontal'}>

                                    </GlassesList>
                                  ) : (
                                    <></>
                                  )
                                  }
                                </>
                              ) : (
                                <>
                                  <BrandsPage
                                    allBrands={catalogs}
                                    handleSelectedBrand={handleSelectedBrand}
                                    translate={translate}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {startSelectionLanguage}

                      <LanguageSelector
                        onSelectLanguage={handleSelectLanguage} startSelectionLanguage={startSelectionLanguage}
                      ></LanguageSelector>
                    </>
                  )}
                  <Footer />
                </div>
                {/* </div>
        </div> */}
              </div>
            </>
          ) : (
            <>
              <MirrorScreenSever
                handleStartNewSession={handleStartNewSession}
              ></MirrorScreenSever>
            </>
          )}
        </div>
      ) : (
        <>
          <LoadingScreen loadingProgress={loadingProgress}></LoadingScreen>
        </>
      )}
    </>
  );
}

export default SelectionPage;