import React, { useEffect, useState, useRef } from "react";
import styles from "./SingleVto.module.css"
// import styles from "../component/SingleVtoSafilo.module.css"
import axios from "axios";

import icon from "../asset/arshades_logo_take_pic.png"
/* index.css */

import { faDownload, faShareNodes, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import { emailTemplate } from "../data/emailTemplate";
import { AXIOS_HEADERS, EMAIL_FROM, EMAIL_URL } from "../Utils/constant";
import { uploadBase64 } from "../Utils/function";

import gsap from "gsap";

function ModalPhotoShare(props) {

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const [sendEmail, setSendEmail] = useState(false);
  const [formData, setFormData] = useState({ email: '', name: '', surname: '', consent: false });
  const [emailMsg,setMsg] = useState("");
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  useEffect(() => {
    if (ref1.current && ref2.current) {
      if (sendEmail) {
        // Animazione per far apparire il div
        gsap.to(ref1.current, { y: 0, opacity: 1, zIndex: 2, duration: 0.5, delay:0.5 });
         gsap.to(ref2.current, { y: 100, opacity: 0, zIndex: -1, duration: 0.5 });
        gsap.to(ref3.current, { y: 100, opacity: 0, zIndex: -1, duration: 0 });

      } else {
        // Animazione per nascondere il div
        gsap.to(ref1.current, { y: 100, opacity: 0, zIndex: -1, duration: 0 });
        gsap.to(ref3.current, { y: 100, opacity: 0, zIndex: -1, duration: 0 });

      }
    }
  }, [sendEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const subject = "your ARShades photo is here";
    if(!formData.email) return setMsg("Please provide an email to continue");
    // Constructing the request body
    
  const imgUrl = await uploadBase64(props.image,formData.email.slice(0,formData.email.indexOf('@')));

    const requestBody = {
      from: EMAIL_FROM,
      to: formData.email, // Replace with the actual destination email
      subject: subject,
      // text: '',
      html: emailTemplate(subject, formData.email, imgUrl,"Your special shot"),//optional
    };

    //send request to email
    const res = await axios.post(EMAIL_URL, requestBody,{headers:AXIOS_HEADERS});
    if(res.status){
      gsap.to(ref1.current, { y: 100, opacity: 0, zIndex: 2, duration: 0.5});

      gsap.to(ref3.current, { y: 0, opacity: 1, zIndex: 2, duration: 0.5 ,delay:0.5});

      setMsg("Email Sent Successfully");
    }else{
      setMsg("Failed to send email");
    }
    // 
    // Qui dovrai implementare la logica per inviare l'email
    // Questo generalmente richiede un backend o un servizio terzo

    // setShowForm(false); // Chiudi il form dopo l'invio
  };

 

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Titolo dell\'immagine',
        text: 'Descrizione dell\'immagine',
        url: props.image
      })
        .then(() => console.log('Contenuto condiviso'))
        .catch((error) => console.log('Errore durante la condivisione', error));
    } else {
      console.log('La Web Share API non è supportata su questo browser');
    }
  }

  const animateAndSet = ()=> {

    // gsap.to(ref2.current, { opacity: 0, x: 100, duration: 1, onComplete: () => {
    //   setSendEmail(true);
    //  } 
    // });
    setSendEmail(true);
    // gsap.fromTo(ref1.current, { opacity: 0, y: -100 }, { opacity: 1, y: 0, duration: 10 });

  }

  return (
    <div className={styles["modal-backdrop"]} >
      <div className={props.isSingleView ? styles["share-modal-single"] : styles["share-modal"]}>
        <div className={styles["close-icon"]} onClick={props.closeAction}>  <FontAwesomeIcon icon={faXmarkCircle} className={styles["icon-share-photo"]} /></div>
        <img className={styles["photo-modal"]} src={props.image} />

        
          <div className={styles["share-line-modal-two"]} ref={ref1}>
          <form onSubmit={handleSubmit}>
            <input type="email" name="email" className={styles["cs-input"]} value={formData.email} onChange={handleInputChange} placeholder="Click here to enter your email
" />
            {/* <input type="text" name="name" value={formData.name} onChange={handleInputChange} placeholder="Nome" />
            <input type="text" name="surname" value={formData.surname} onChange={handleInputChange} placeholder="Cognome" /> */}
            {/* <label>
              <input type="checkbox" name="consent" checked={formData.consent} onChange={handleInputChange} />
              Consenso al trattamento dei dati
            </label> */}
            
            <button type="submit" className={styles["cs-input-button-two"]}>Email this picture</button>

          </form>

          <div>
            {/* <a href={props.image} download={props.model.nomeOcchiale + ' '+ props.model.coloreFrame + '.jpg'}>
              <FontAwesomeIcon icon={faDownload} className={styles["icon-share-photo"]} />
            </a> */}
          </div>
        </div>
       
              <div className={styles["share-line-modal"]}  ref={ref2} >

             <button className={styles["cs-input-button"]} onClick={animateAndSet}>Get this image via email</button>
             </div>
       
             <div className={styles["mesage-success"]} ref={ref3}>{emailMsg}</div>

        
      </div>
    </div>
  );

}

export default ModalPhotoShare;