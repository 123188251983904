import React, { useState, useEffect, useRef } from 'react';
import Slider, { default as SliderHandle } from 'rc-slider';
import 'rc-slider/assets/index.css';
import styles from "./SingleVto.module.css"
// import styles from "../component/SingleVtoSafilo.module.css"
import { PRIMARY_COLOR } from '../Utils/constant';

function SliderComponent({ handleSelectedIndex }) {
  const vertexBufferIndex = [10, 168, 6, 197, 195, 5];
  const [selectedIndex, setSelectedIndex] = useState(vertexBufferIndex[2]);
  const [sliderValue, setSliderValue] = useState(2); // valore iniziale del cursore del Slider
  
  
  const handleSliderChange = (value) => {
    const indexMesh = vertexBufferIndex[value];
    // console.log("change", value)
    setSelectedIndex(indexMesh);
    handleSelectedIndex(indexMesh);
  };

  const handleResetIndex = () => {
    setSelectedIndex(vertexBufferIndex[2]); // imposta l'indice selezionato come quello iniziale
    setSliderValue(2); // imposta il valore del cursore alla posizione iniziale
    handleSelectedIndex(vertexBufferIndex[2]);
  };

  const handleStyle = {
    backgroundColor: '#000000',
  };

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderHandle value={value} style={handleStyle} {...restProps}>
        {dragging ? vertexBufferIndex[index] : ''}
      </SliderHandle>
    );
  };

  return (
    <div className={styles["slider-sub-container"]}>

      <div className={styles["slider-menu-container"]}>
        <Slider
          value={sliderValue}
          vertical
          dots
          dotStyle={{ backgrounColor:PRIMARY_COLOR, borderColor: PRIMARY_COLOR, width:20, height:20}}
          activeDotStyle={{ borderColor: PRIMARY_COLOR }}
          min={0}
          max={vertexBufferIndex.length - 1}
          handle={handle}
          onChange={(value) => {
            setSliderValue(value);
            handleSliderChange(value);
          }}
          railStyle={{ backgroundColor: '#cccccc' }}
          trackStyle={{ backgroundColor: PRIMARY_COLOR }}
          handleStyle={{
            borderColor: 'white',
            height: 40,
            width: 40,
            backgroundColor: 'white',
            opacity: 1,
          }}
        />
      </div>

      {/* <div className={styles["reset-menu-container"]} onClick={handleResetIndex}> Reset</div> */}

    

      </div>
  );
}

export default SliderComponent;
