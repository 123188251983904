export const emailTemplate = (subject = '', email = '', image = '', body = '') => {


  return `<!--
  * This email was built using Tabular.
  * For more information, visit https://tabular.email
  -->
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
  <head>
  <title></title>
  <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <!--<![endif]-->
  <meta name="x-apple-disable-message-reformatting" content="" />
  <meta content="target-densitydpi=device-dpi" name="viewport" />
  <meta content="true" name="HandheldFriendly" />
  <meta content="width=device-width" name="viewport" />
  <meta name="format-detection" content="telephone=no, date=no, address=no, email=no, url=no" />
  <style type="text/css">
  table {
  border-collapse: separate;
  table-layout: fixed;
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt
  }
  table td {
  border-collapse: collapse
  }
  .ExternalClass {
  width: 100%
  }
  .ExternalClass,
  .ExternalClass p,
  .ExternalClass span,
  .ExternalClass font,
  .ExternalClass td,
  .ExternalClass div {
  line-height: 100%
  }
  body, a, li, p, h1, h2, h3 {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  }
  html {
  -webkit-text-size-adjust: none !important
  }
  body, #innerTable {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
  }
  #innerTable img+div {
  display: none;
  display: none !important
  }
  img {
  Margin: 0;
  padding: 0;
  -ms-interpolation-mode: bicubic
  }
  h1, h2, h3, p, a {
  line-height: 1;
  overflow-wrap: normal;
  white-space: normal;
  word-break: break-word
  }
  a {
  text-decoration: none
  }
  h1, h2, h3, p {
  min-width: 100%!important;
  width: 100%!important;
  max-width: 100%!important;
  display: inline-block!important;
  border: 0;
  padding: 0;
  margin: 0
  }
  a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important
  }
  a[href^="mailto"],
  a[href^="tel"],
  a[href^="sms"] {
  color: inherit;
  text-decoration: none
  }
  </style>
  <style type="text/css">
  @media (min-width: 481px) {
  .hd { display: none!important }
  }
  </style>
  <style type="text/css">
  @media (max-width: 480px) {
  .hm { display: none!important }
  }
  </style>
  <style type="text/css">
  [style*="Inter"] {font-family: 'Inter', BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif !important;}
  @media only screen and (min-width: 481px) {.t9{padding-left:50px!important;padding-right:50px!important}.t11{padding-left:50px!important;padding-right:50px!important;width:500px!important}.t25,.t35,.t45,.t55{width:600px!important}.t68{padding-left:50px!important;padding-right:50px!important}.t70{padding-left:50px!important;padding-right:50px!important;width:500px!important}.t106,.t117,.t127,.t84,.t95{width:600px!important}}
  </style>
  <style type="text/css" media="screen and (min-width:481px)">.moz-text-html .t9{padding-left:50px!important;padding-right:50px!important}.moz-text-html .t11{padding-left:50px!important;padding-right:50px!important;width:500px!important}.moz-text-html .t25,.moz-text-html .t35,.moz-text-html .t45,.moz-text-html .t55{width:600px!important}.moz-text-html .t68{padding-left:50px!important;padding-right:50px!important}.moz-text-html .t70{padding-left:50px!important;padding-right:50px!important;width:500px!important}.moz-text-html .t106,.moz-text-html .t117,.moz-text-html .t127,.moz-text-html .t84,.moz-text-html .t95{width:600px!important}</style>
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&amp;display=swap" rel="stylesheet" type="text/css" />
  <!--<![endif]-->
  <!--[if mso]>
  <style type="text/css">
  td.t9{padding-left:50px !important;padding-right:50px !important}td.t11{padding-left:50px !important;padding-right:50px !important;width:600px !important}td.t25,td.t35,td.t45,td.t55{width:600px !important}td.t68{padding-left:50px !important;padding-right:50px !important}td.t70{padding-left:50px !important;padding-right:50px !important;width:600px !important}td.t106,td.t117,td.t127,td.t84,td.t95{width:600px !important}
  </style>
  <![endif]-->
  <!--[if mso]>
  <xml>
  <o:OfficeDocumentSettings>
  <o:AllowPNG/>
  <o:PixelsPerInch>96</o:PixelsPerInch>
  </o:OfficeDocumentSettings>
  </xml>
  <![endif]-->
  </head>
  <body class="t0" style="min-width:100%;Margin:0px;padding:0px;background-color:#E3E3E3;"><div class="t1" style="background-color:#E3E3E3;"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" border="0" align="center"><tr><td class="t2" style="font-size:0;line-height:0;mso-line-height-rule:exactly;background-color:#E3E3E3;" valign="top" align="center">
  <!--[if mso]>
  <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false">
  <v:fill color="#E3E3E3"/>
  </v:background>
  <![endif]-->
  <table role="presentation" width="100%" cellpadding="0" cellspacing="0" border="0" align="center" id="innerTable"><tr><td>
  <table class="t10" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t11" style="background-color:#FFFFFF;border-top:4px solid #42B1AC;width:420px;padding:50px 30px 50px 30px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t11" style="background-color:#FFFFFF;border-top:4px solid #42B1AC;width:480px;padding:50px 30px 50px 30px;"><![endif]-->
  <table role="presentation" width="100%" cellpadding="0" cellspacing="0"><tr><td>
  <table class="t14" role="presentation" cellpadding="0" cellspacing="0" align="left"><tr>
  <!--[if !mso]><!--><td class="t15" style="width:40px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t15" style="width:40px;"><![endif]-->
  <div style="font-size:0px;"><img class="t21" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="40" height="38.5625" alt="" src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/MirrorImage%2FARShades%20Logo%20Landscape-transp%20(1).png?alt=media&token=f445b39d-4caa-4111-8ce2-3df06feb8fbc"/></div></td>
  </tr></table>
  </td></tr><tr><td><div class="t13" style="mso-line-height-rule:exactly;mso-line-height-alt:64px;line-height:64px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
  <table class="t24" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t25" style="width:480px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t25" style="width:480px;"><![endif]-->
 
  </tr></table>
  </td></tr><tr><td><div class="t23" style="mso-line-height-rule:exactly;mso-line-height-alt:26px;line-height:26px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
  <table class="t34" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t35" style="width:480px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t35" style="width:480px;"><![endif]-->
  <p class="t41" style="margin:0;Margin:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:26px;font-weight:400;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#505050;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Your photo with the ARShades mirror is here. You can download it by clicking on the button below
  </p></td>
  </tr></table>
  </td></tr><tr><td><div class="t33" style="mso-line-height-rule:exactly;mso-line-height-alt:26px;line-height:26px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td><div class="t134" style="mso-line-height-rule:exactly;mso-line-height-alt:40px;line-height:40px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
  <table class="t136" role="presentation" cellpadding="0" cellspacing="0" align="left"><tr>
  <!--[if !mso]><!--><td class="t137" style="border:2px solid #747474;overflow:hidden;width:152px;text-align:center;line-height:36px;mso-line-height-rule:exactly;mso-text-raise:6px;border-radius:40px 40px 40px 40px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t137" style="border:2px solid #747474;overflow:hidden;width:156px;text-align:center;line-height:36px;mso-line-height-rule:exactly;mso-text-raise:6px;border-radius:40px 40px 40px 40px;"><![endif]-->
  <span class="t143" style="display:block;margin:0;Margin:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:36px;font-weight:700;font-style:normal;font-size:16px;text-decoration:none;direction:ltr;color:#505050;text-align:center;mso-line-height-rule:exactly;mso-text-raise:6px;"><a href="${image}"><span class="t146" style="margin:0;Margin:0;mso-line-height-rule:exactly;">Down</span>load</span></a></td>
  </tr></table>
  </td></tr><tr><td><div class="t135" style="mso-line-height-rule:exactly;mso-line-height-alt:40px;line-height:40px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
  <table class="t44" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t45" style="width:480px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t45" style="width:480px;"><![endif]-->
  <p class="t51" style="margin:0;Margin:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:26px;font-weight:400;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#505050;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">If the button doesn't work, you can use the link below to download.
  <br/> ${image}</p></td>
  
  </tr></table>
  </td></tr><tr><td>
  <table class="t116" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t117" style="width:480px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t117" style="width:480px;"><![endif]-->
  <p class="t123" style="margin:0;Margin:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:26px;font-weight:400;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#505050;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Thank you for your trust,</p></td>
  </tr></table>
  </td></tr><tr><td><div class="t115" style="mso-line-height-rule:exactly;mso-line-height-alt:26px;line-height:26px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
  <table class="t54" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t55" style="width:480px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t55" style="width:480px;"><![endif]-->
  <p class="t61" style="margin:0;Margin:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:26px;font-weight:400;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#505050;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">The Spaarkly team</p></td>
  </tr></table>
  </td></tr></table></td>
  </tr></table>
  </td></tr><tr><td>
  <table class="t69" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t70" style="background-color:#F5F5F5;width:420px;padding:40px 30px 100px 30px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t70" style="background-color:#F5F5F5;width:480px;padding:40px 30px 100px 30px;"><![endif]-->
  <table role="presentation" width="100%" cellpadding="0" cellspacing="0"><tr><td>
  <table class="t73" role="presentation" cellpadding="0" cellspacing="0" align="left"><tr>
  <!--[if !mso]><!--><td class="t74" style="width:113px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t74" style="width:113px;"><![endif]-->
  <div style="font-size:0px;"><img class="t80" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="113" height="66.46875" alt="" src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/MirrorImage%2Flogo%202.0-03.png?alt=media&token=7eaba560-86d7-4330-b0af-5544f6d42754"/></div></td>
  </tr></table>
  </td></tr><tr><td><div class="t72" style="mso-line-height-rule:exactly;mso-line-height-alt:60px;line-height:60px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
  <table class="t83" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t84" style="width:480px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t84" style="width:480px;"><![endif]-->
  <p class="t90" style="margin:0;Margin:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:26px;font-weight:400;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#505050;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;"><a class="t91" href="https://www.arshades.it/privacy-policy/" style="margin:0;Margin:0;line-height:32px;font-weight:400;font-style:normal;font-size:16px;text-decoration:underline;direction:ltr;color:#959595;mso-line-height-rule:exactly;mso-text-raise:5px;" target="_blank">Privacy Policy</a></p></td>
  </tr></table>
  </td></tr><tr><td>
  <table class="t105" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t106" style="width:480px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t106" style="width:480px;"><![endif]-->
  <p class="t112" style="margin:0;Margin:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:26px;font-weight:400;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#505050;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;"><a class="t113" href="https://www.arshades.it/cookie-policy/" style="margin:0;Margin:0;line-height:32px;font-weight:400;font-style:normal;font-size:16px;text-decoration:underline;direction:ltr;color:#959595;mso-line-height-rule:exactly;mso-text-raise:5px;" target="_blank">Cookie Policy</a></p></td>
  </tr></table>
  </td></tr><tr><td>
  <table class="t94" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t95" style="width:480px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t95" style="width:480px;"><![endif]-->
  <p class="t101" style="margin:0;Margin:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:26px;font-weight:400;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#505050;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">hello@spaarkly.it</p></td>
  </tr></table>
  </td></tr><tr><td><div class="t93" style="mso-line-height-rule:exactly;mso-line-height-alt:50px;line-height:50px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
  <table class="t126" role="presentation" cellpadding="0" cellspacing="0" align="center"><tr>
  <!--[if !mso]><!--><td class="t127" style="width:480px;">
  <!--<![endif]-->
  <!--[if mso]><td class="t127" style="width:480px;"><![endif]-->
  </tr></table>
  </td></tr></table></td>
  </tr></table>
  </td></tr></table></td></tr></table></div></body>
  </html>`
}

