
import React from 'react';
import CardContainerTwoC from '../container/CardContainerTwoC';
import CardButton from './CardButton';
import style from './Footer.module.css'
import IMAGES from '../../data/images';
import MirrorTittle from './MirrorHeader';


function Footer() {
    return (
        <>
            <div className={style.footer} >
                <img src={IMAGES.footer} className={style["footer-image"]} />
            </div>

        </>
    );
}

export default Footer;

  