import React, { useState, useRef, useEffect, forwardRef } from 'react';
import styles from './CardContainerTwoC.module.css';
import gsap from 'gsap';

const CardContainerHorizontal = forwardRef(({ children, margin }, ref) => {
    // PRIMA CARD ATTIVA
    const [activeIndex, setActiveIndex] = useState(0);
    const observerRef = useRef(null);

    useEffect(() => {
        const container = ref.current;
        if (!container) return;

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = parseInt(entry.target.dataset.index, 10);
                    setActiveIndex(index);
                }
            });
        };

        observerRef.current = new IntersectionObserver(callback, {
            root: container,
            rootMargin: '0px',
            threshold: 0.5,
        });

        container.querySelectorAll('[data-index]').forEach((element) => {
            observerRef.current.observe(element);
        });

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, [children]);

    const handleCardClick = (index, onCardClick) => {
        const container = ref.current;
        const childWidth = container.firstChild.offsetWidth + parseInt(window.getComputedStyle(container).gap, 10);
        const scrollPosition = childWidth * index - (container.offsetWidth - childWidth) / 2;
    
        // Aggiungi la verifica per assicurarti che glasses sia definito e contenga la proprietà brandId
        if (children[index]?.props?.glasses?.brandId) {
            if (index === activeIndex) {
                if (onCardClick) onCardClick();
            } else {
                container.scrollTo({
                    left: scrollPosition,
                    behavior: 'smooth',
                });
                setActiveIndex(index);
            }
        }
    };    

    useEffect(() => {
        const container = ref.current;
        if (container) {
            const childWidth = container.firstChild.offsetWidth + parseInt(window.getComputedStyle(container).gap, 10);
            const scrollPosition = childWidth * activeIndex - (container.offsetWidth - childWidth) / 2;
            container.scrollTo({
                left: scrollPosition,
                behavior: 'smooth',
            });

            // GSAP Animation
            const cards = document.querySelectorAll(`.${styles['card-horizontal']}`);
            if (cards.length > 0) {
                gsap.fromTo(cards[0], { scale: 0.9 }, { scale: 1, duration: 1 });
            }
        }
    }, []);

    return (
        <div
            className={styles['card-horizontal-container']}
            ref={ref}
            style={{ marginTop: margin ? margin : '40%' }}
        >
            {React.Children.map(children, (child, index) => (
                <div
                    data-index={index}
                    className={`${styles['card-horizontal']} ${activeIndex === index ? styles['active-card'] : ''}`}
                    onClick={() => handleCardClick(index, child.props.onCardClick)}
                >
                    {React.cloneElement(child, { isActive: activeIndex === index })}
                </div>
            ))}
        </div>
    );
});

export default CardContainerHorizontal;