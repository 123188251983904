import React, { useEffect, useState, useRef, forwardRef } from "react";
import CardContainerTwoC from "../container/CardContainerTwoC";
import CardButton from "./CardButton";
import style from "./ModelViewer.module.css";
import { getStorage, ref, getMetadata, uploadBytes, getDownloadURL  } from "firebase/storage";
import { doc, updateDoc } from 'firebase/firestore';
import {db} from '../../data/base'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faPlayCircle
} from "@fortawesome/free-solid-svg-icons";
const ModelViewerComponent = forwardRef(({ glassesVariant , onComplete,currentNumber},ref) => {

  const modelViewerRef = useRef(null);
//   const [rotation, setRotation] = useState(0);
  const [fieldOfView ,setFieldOfView] = useState("1deg");

  const [animationAvailable, setAnimationAvailable] = useState(false);
  const [animationStarted, setAnimationStarted] = useState(false);


  // useEffect(() => {
  //   console.log("Lista Varianti", glassesVariant);
  //   let model = glassesVariant[0];
   
  const startAnimation = () => {

    const modelViewer = modelViewerRef.current;
    if(!animationStarted){
    console.log("start");

    if (animationAvailable) {
      // Avvia l'animazione
      modelViewer.animationName = "Clip on full";
      modelViewer.timeScale = 1;

      modelViewer.play({ repetitions: 1 });

      // Imposta animationStarted su true in modo che il pulsante non possa essere premuto di nuovo
      setAnimationStarted(true);
    }
    }else {
      modelViewer.animationName = "Clip on full";
      modelViewer.timeScale = -1;
      modelViewer.play({ repetitions: 1 });
      setAnimationStarted(false);

    }
  
  };

    
  //   // console.log("Occhiale", model);
  //   setSelectedVariant(model);

  // }, [glassesVariant]);


  useEffect(()=>{
    if(modelViewerRef.current !== null){
        modelViewerRef.current.addEventListener('load', function(evt) {
            if( modelViewerRef.current?.cameraOrbit){
              modelViewerRef.current.cameraOrbit =  "20.26deg 87.4deg 0.4259m";
              setFieldOfView("45deg");
            }
             // Impostare i limiti di zoom
          modelViewerRef.current.maxCameraOrbit = 'auto auto 0.5m'; // Limite massimo di zoom
          modelViewerRef.current.minCameraOrbit = 'auto auto 0.3m'; // Limite minimo di zoom

          const modelViewer = modelViewerRef.current;
          //console.log("IS PRESENT", modelViewer.availableAnimations[0]);

          if(modelViewer.availableAnimations[0] ){
            modelViewer.animationName = "Clip on full"
            setAnimationAvailable(true);
            console.log("IS PRESENT", modelViewer.availableAnimations);
          }
          })
    }
  },[])
  




  return (
    <>
      <div className={style.container}>
        <model-viewer
        disable-pan
          exposure="1.1"
          src={glassesVariant?.urlGlbComplete}
          environment-image={"./asset/hdr/neon_photostudio_1k.hdr"}
          tone-mapping="commerce"
          shadow-intensity="0"
          camera-controls
        //   camera-orbit="25.9deg 56.14deg 0.4277m"
        //   field-of-view="30deg"
          camera-orbit="-35.633deg -26.14deg 10.1831m" 
          field-of-view={fieldOfView}
        //   min-camera-orbit="auto auto 0.3914m"
        //   min-field-of-view="26.26deg"
          ref={modelViewerRef}
          min-camera-orbit="auto auto 0.3552m" 
          min-field-of-view="22.98deg"
        >
          <div className="progress-bar hide" slot="progress-bar">
            <div className="update-bar"></div>
          </div>
        </model-viewer>
{/* ${variant.esaColorLensesPrimary} 0.8%, ${variant.esaColorLensesSecondary} 0.1% */}
{animationAvailable && (
        <button className={style.clipped_button}onClick={startAnimation}>
        <FontAwesomeIcon icon={faPlayCircle} className={style['clipped-icon-button']}/> {!animationStarted ? 'APPLY CLIP-ONS' : 'REMOVE CLIP-ONS'}
   </button>
      )}
      </div>
     
    </>
  );
});

export default ModelViewerComponent;
