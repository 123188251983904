  // App.js
import { useRef, useEffect, useState, useContext } from "react";

import { Rings } from "react-loader-spinner";

import { getStorage, ref, getMetadata } from "firebase/storage";

import { db } from "../data/base";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  limit,
  addDoc,
} from "@firebase/firestore";
import { whitelist } from "../Utils/constant";

import {
  IOS_APP_STORE_URL,
  ANDROID_APP_STORE_URL,
  MESSAGE_TITLE,
  MESSAGE_SUBTITLE,
  WEBSITE,
} from "../Utils/constant";

import { templateConverter } from "../modello/TakeFotoTemplate";
import { tagliaConverter } from "../modello/Taglia";
import { modelloConverter } from "../modello/Modello";
import { brandConverter } from "../modello/Brand";
import { lineaConverter } from "../modello/Linea";
import Webcam from "react-webcam";

import PermissionAndPolicy from "../component/PermissionAndPolicy";

import styles from "../component/SingleVto.module.css";
// import styles from "../component/SingleVtoSafilo.module.css"

import ModalQR from "../component/ModalQR";
import SaveFrameButton from "../component/SaveFrameButton";
import ModalPhotoShare from "../component/ModalPhotoShare";
import MiniaturePhoto from "../component/MiniaturePhoto";
import SliderComponent from "../component/SliderComponent";
import HdIcon from "../asset/icon.svg";
import Lottie from "react-lottie-player";
import animationData from "../asset/lottie-loading.json";
import SessioneWeb from "../Utils/SessionWeb";
import TakeFotoTamplate from "../modello/TakeFotoTemplate";
import { LOGO } from "../Utils/constant";
import { useNavigate } from "react-router-dom";

import {
  faRotateRight,
  faArrowLeft,
  faQrcode,
  faPlayCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Register WebGL backend.
// import "@mediapipe/face_mesh";

import EffectRenderer from "./EffectRenderer";
import VariantCard from "../component/VariantCard";
import { CatalogDataContext } from "../data/CatalogueDataContext";

import faceMeshSingleton from "../Utils/FaceMeshSingleton";
import FooterVtoMenu from "../component/mirror-component/FooterVtoMenu";
import Footer from "../component/mirror-component/Footer";
import SizeSelector from "../component/mirror-component/GlassesSize";
import MirrorVtoMenu from "../component/mirror-component/MirrorVtoMenu"; 

function MirrorVto(props) {
  const {
    catalogs,
    listOfGlasses,
    loadingProgress,

    //Function
    filterGlassesByGender,
    //Boolean
    isfilteredByGender,
    //set

    // list Tags
    listOfTags,
    // Function for get categories
    getAllFormCategories,

    //function for filtered
    filterGlassesByForm,
    //Boolean
    isFilteredByShape,

    filteredGlassesList,
    saveFilteredList,

    //function for set Glasses
    saveSelectedGlasses,
    //Glasses Selected
    selectedGlasses,

    //function for set Variant
    saveSelectedVariant,
    //Variant Selected
    selectedVariant,

    //function for set Brand
    saveSelectedBrand,
    //Brand Selected
    selectedBrand,

    //Utils Fanction
    getCatalogById,
    getGlassesByBrand,
    getAllVariant,

    clearSession,
    setStartNewSession,
    isNewSession,

    //PAGE STATE
    updatePageStateInDB,
    isBrandPage,
    isSingleBrandPage,
    isSingleGlassesPage,
    startSelectionLanguage,

    selectedGender,
    selectedShape,
    saveSelectedShape,
    saveSelectedGender,
    resetFilter,
    allShapes,
    setAllShapes,
  } = useContext(CatalogDataContext);

  const [isClipped, setIsClipped] = useState(false)
  const [faceMesh, setFaceMesh] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [screenTransaction, setscreenTransaction] = useState(false);

  const [videoNode, setVideoNode] = useState(null); // Stato per memorizzare il nodo video
  const [loadAttempted, setLoadAttempted] = useState(false); // Stato per tenere traccia se handleVideoLoad è stata chiamata

  //Logica di reset
  const [countFrame, setCountFrame] = useState(0);
  const [hasReset, setHasReset] = useState(false);

  const [VIDEO_WIDTH, setWindowsWidth] = useState(null);
  const [VIDEO_HEIGHT, setWindowsHeight] = useState(null);
  const [VIDEO_WIDTH2, setWindowsWidth2] = useState(null);
  const [VIDEO_HEIGHT2, setWindowsHeight2] = useState(null);
  const webcamRef = useRef();
  const webcamRef2 = useRef();

  const canvasRef = useRef();
  const downscaledCanvasRef = useRef();
  const originalCanvasRef = useRef();

  //   const videoRef = useRef();
  const [glassesData, setVtoDataGlasses] = useState(null);
  const [sizeData, setVtoDataSize] = useState();
  const [modelData, setVtoDataModel] = useState(null);
  const [lineData, setVtoDataLinea] = useState();
  const [brandData, setVtoDataBrand] = useState();

  const vertexBufferIndex = [10, 168, 6, 197, 195, 5];
  const [effectRenderer, setEffectRenderer] = useState();
  const [selectedVariantGlb, setSelectedVariant] = useState(null);
  const [selectedCode, setSelectedCode] = useState();
  const [selectedCard, setSelectedCard] = useState(null);
  const [slectedGlassData, setSelectedModelData] = useState(null);
  const [firstSel, setFirstSelected] = useState(true);
  const [isMenuCreated, setIsMenuCreated] = useState(false);
  const [modalTakePhoto, setIsModalOpen] = useState(false);
  const [imagePhoto, setTakePhoto] = useState(null);
  const [isFisrtPhoto, setIsFirstPhoto] = useState(false);
  const [selectedSizeCode, setSelectedSizeCode] = useState(null);
  const cardRefs = useRef([]);
  const [frameWithoutPeople, setWidthOutPeople] = useState(0);
  const [loadingScene, setLoadingScene] = useState(true);
  const [isInIFrame, setIsInIframe] = useState(false);
  const [takePicTemplate, setTakePicTemplate] = useState(
    new TakeFotoTamplate()
  );
  const [selectedIndex, setSelectedIndex] = useState(vertexBufferIndex[2]);
  const glassRefForSave = useRef(null);
  const menuRef = useRef(null);

  const [isAnimated, setIsAnimated] = useState(false)
  const [HDOpen, setHDOpen] = useState(false);

  const [listLenght, setListLenght] = useState(0);

  //Function for reset session
  useEffect(() => {
    // console.log("IN HANDLE", countFrame);

    if (countFrame > 400 && !hasReset) {
      // console.log("ON COUNT");
      setStartNewSession(false);
      handleReset();
      setTimeout(() => {
        window.location.href = "/"; // Cambia la pagina corrente con un nuovo URL
      }, [1000]);
      setHasReset(true);
    }

    // Reimposta hasReset quando countFrame torna a 0
    if (countFrame === 0) {
      setHasReset(false);
    }
  }, [countFrame, hasReset]);

  //Function For Init Mediapipe
  useEffect(() => {
    const faceMeshInit = faceMeshSingleton.getFaceMesh();
    setFaceMesh(faceMeshInit);
    faceMeshSingleton.onLoad((loaded) => {
      setIsLoaded(loaded);
    });
  }, []);

  //Notify video Load
  useEffect(() => {
    if (isLoaded) {
      handleVideoLoad(videoNode);
    }
  }, [isLoaded]);

  //Function For Reset session
  const handleReset = () => {
    saveFilteredList(listOfGlasses);
    resetFilter();
    updatePageStateInDB(false, false, false, false, false, false);
    saveSelectedBrand(null);
    saveSelectedGlasses(null);
    saveSelectedVariant(null);
  };

  //Feacthing and Data Set
  useEffect(() => {
    async function featchData() {
      if (loadingProgress === 100) {
        //console.log("Loading Complete", selectedVariant);
        if(!selectedVariant){
          window.location.href = "/"; // Cambia la pagina corrente con un nuovo URL
        }
        let m = selectedVariant.id;
        let c = selectedBrand.id;
        const docInitVariant = selectedVariant;
        const occhiale = selectedGlasses;
        setVtoDataGlasses(occhiale);
        const listaSize = occhiale.listaTaglieOBJ;
        const docBrand = selectedBrand;

        setVtoDataBrand(docBrand);
        setVtoDataSize(listaSize);

        let listaModel = null;
        if (listaSize.length == 1) {
          setSelectedSizeCode(listaSize[0].codiceTaglia);
          listaModel = listaSize[0].listaModelli;
        } else {
          listaSize.map((size, i) => {
            const listModelSize = size.listaModelli;
            listModelSize.map((model) => {
              if (model.id === m) {
                setSelectedSizeCode(listaSize[i].codiceTaglia);
                listaModel = size.listaModelli;
              }
            });
          });
        }

        setListLenght(listaModel.length);

        setVtoDataModel(listaModel);
        if (listaModel.length == 1) {
          setSelectedModelData(listaModel[0]);
          glassRefForSave.current = listaModel[0];
          setSelectedVariant(listaModel[0].urlGlb);
          // console.log(model.urlProductPage)
          // console.log(i)
          setSelectedCode(0);
        } else {
          listaModel.map((model, i) => {
            // console.log(model,m)

            if (model.id === m) {
              setSelectedModelData(model);
              glassRefForSave.current = model;
              setSelectedVariant(model.urlGlb);
              // console.log(model.urlProductPage)
              // console.log(i)
              setSelectedCode(i);
            }
          });
        }

        setListLenght(listaModel.length);
      }
    }

    featchData();
    // checkDeviceAndBrowser();
  }, [loadingProgress]);

  //Init Effect Render
  useEffect(() => {
    if (canvasRef.current != null && modelData != null) {
      const canvas = canvasRef.current;
      if (effectRenderer != null) {
        effectRenderer.setUrl(selectedVariantGlb);
        effectRenderer.setIndex(selectedIndex);
        //  console.log("change", selectedVariant);
      } else {
        const effectRenderer = new EffectRenderer(canvas, {
          width: VIDEO_WIDTH,
          height: VIDEO_HEIGHT,
        });
        // console.log("consumption effect", dataConsumption);
        // effectRenderer.setDataConsumption(dataConsumption);

        // console.log("brand",brandData?.hdr_environment)
        effectRenderer.loadModel(selectedVariantGlb);
        setEffectRenderer(effectRenderer);

        // console.log(brandData.url_logo);
        effectRenderer.setBrandLogo(brandData.url_logo);
      }
    }
  }, [canvasRef, selectedVariantGlb, selectedIndex, effectRenderer, brandData]);

  //Mediapipe Start end Render
  let countData = 0;
  async function handleVideoLoad(videoNode) {
    if (!loadAttempted) {
      setVideoNode(videoNode);
      //console.log(" setto onLoad");
      setWindowsWidth(videoNode?.target.clientWidth);
      setWindowsHeight(videoNode?.target.clientHeight);
    }


    if (!isLoaded) {
      console.log("NON INIZIALIZZATO");
      return; // Non fare nulla se FaceMesh non è ancora caricato
    } else {
      //console.log("INIZIALIZZATO", faceMesh, videoNode);

      setTimeout(() => {
        setscreenTransaction(true);
      }, 500);
    }

    videoNode?.target.play();
    let i = 0;
    if (videoNode) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: videoConstraintsMirror.width,
            height: videoConstraintsMirror.height,
          },
        });

        let video = null;
        if (videoNode?.target) {
          video = videoNode?.target;
        } else {
          video = videoNode;
        }
        video.play();

        if (video.readyState !== 4) return;
        const track = webcamRef.current?.stream?.getVideoTracks()[0];
        // console.log(track);
        let settings = null;
        const canvas = canvasRef.current;

        let effectRenderer = null;
        if (track) {
          settings = track.getSettings();
          const { orientation } = window;

          setWindowsWidth(videoNode?.target.clientWidth);
          setWindowsHeight(videoNode?.target.clientHeight);
          effectRenderer = new EffectRenderer(canvas, {
            width: videoNode?.target.clientWidth,
            height: videoNode?.target.clientHeight,
          });
        }

        if (countData == 0) {
          countData = countData + 1;
        }

        effectRenderer.loadModel(selectedVariantGlb);
        setEffectRenderer(effectRenderer);

        if (effectRenderer != null) {
          // console.log(effectRenderer);

          if (glassesData !== null) {
            // console.log("Coeff", glassesData.glasses_inclination_coeff);
            effectRenderer.setCoeFF(
              glassesData.glasses_inclination_coeff,
              glassesData.glasses_y_coeff_android,
              glassesData.glasses_z_coeff_android
            );
          }
          let startTime = 0.0;

          let image = null;

          async function detectionFrame(now, metadata) {
            if (startTime === 0.0) {
              startTime = Date.now();
            }

            setIsAnimated(effectRenderer.isAnimated);
            // const downscaledCanvas = downscaledCanvasRef.current;
            
            // const ctx = downscaledCanvas.getContext('2d');
            // // ctx.translate(downscaledCanvas.width, 0);

            // // // Scala negativamente sull'asse X per riflettere l'immagine
            // // ctx.scale(-1, 1);
            
            // // Disegna l'immagine (che verrà riflessa)
            // ctx.drawImage(videoNode.target, 0, 0, downscaledCanvas.width, downscaledCanvas.height);
            
            // Resetta le trasformazioni per il prossimo frame
            // ctx.setTransform(1, 0, 0, 1, 0, 0);
           const originalCanvas = originalCanvasRef.current
             const ctx2 = originalCanvas.getContext('2d');
             ctx2.drawImage(videoNode.target, 0, 0, originalCanvas.width, originalCanvas.height)
            image = originalCanvas;

            await faceMesh.send({ image: originalCanvas });

            requestAnimationFrame(detectionFrame);
          }

          requestAnimationFrame(detectionFrame);
          let countNoFaces = 0;

          let lastFaceResults = null;
          let lastHandResults = null;

          function processResults() {
            if (lastFaceResults) {
              // Qui puoi elaborare entrambi i set di dati
              effectRenderer.render(lastFaceResults, lastHandResults, image);

              // Resetta le variabili dopo l'elaborazione
              lastFaceResults = null;
              lastHandResults = null;
            }
          }

          // console.log("Processing started");
          faceMesh.onResults((results) => {
            if (results.multiFaceLandmarks) {
              if (results.multiFaceGeometry <= 0) {
                setCountFrame((prevCount) => prevCount + 1);
                // console.log("COUNT")
              } else {
                setCountFrame(0);
              }
              //  console.log(results);
              // effectRenderer.render(results);
              lastFaceResults = results;
              
              processResults();

              setLoadingScene(false);

              // if (countNoFaces >= 100) {
              //   setWidthOutPeople(true);
              //   if (countNoFaces >= 5000) {
              //     window.location.href = "/"; // Cambia la pagina corrente con un nuovo URL
              //   }
              //   // console.log("non c'è nessuno");
              // }
            }
          });
        }
      } catch (error) {
        // Gestisci l'errore qui
        console.error(error.message);
      }
    }
  }

  //Set data for Brand
  useEffect(() => {
    if (brandData && effectRenderer) {
      // console.log("brand Data",brandData.url_logo);
      effectRenderer.setBrandLogo(brandData.url_logo);
      effectRenderer.setBrandDataHdr(brandData.hdr_environment);
      effectRenderer.setTakePicTemplate(takePicTemplate);
      
    }
  }, [brandData]);

  //Action Select variant
  function handleSelectedGlasses(modello, index) {
    setSelectedVariant(modello.urlGlb);
    setSelectedCode(index);
    setSelectedCard(cardRefs.current[index]);
    // console.log(slectedGlassData.id)
    // console.log(slectedGlassData);
    // session.addModelRef(modello.id);
    // session.updateModelloTime(slectedGlassData.id);
    console.log(effectRenderer.consumption);

    setSelectedModelData(modello);
    glassRefForSave.current = modello;
  }

  function handleSelectedIndex(indexMesh) {
    // console.log(indexMesh);
    setSelectedIndex(indexMesh);
  }

  //Function For Menu
  useEffect(() => {
    if (selectedCard && menuRef.current) {
      const menuWidth = menuRef.current.offsetWidth;
      const cardWidth = selectedCard.offsetWidth;
      const cardLeft = selectedCard.offsetLeft;
      const cardOffset = menuWidth / 2 - cardWidth / 2 - cardLeft;
      menuRef.current.scrollTo({
        left: -cardOffset,
        behavior: "smooth",
      });
      setFirstSelected(false);
    }
  }, [selectedCard, isMenuCreated, selectedSizeCode]);

  //Menu Created
  useEffect(() => {
    if (menuRef.current) {
      setIsMenuCreated(true);
    }
  }, []);

  //Smooth Menu
  setTimeout(() => {
    if (firstSel) {
      setSelectedCard(cardRefs.current[selectedCode]);
      if (selectedCard && menuRef.current) {
        const menuWidth = menuRef.current.offsetWidth;
        // console.log(menuWidth);
        const cardWidth = cardRefs.current[selectedCode].offsetWidth;
        const cardLeft = cardRefs.current[selectedCode].offsetLeft;
        const cardOffset = menuWidth / 2 - cardWidth / 2 - cardLeft;
        menuRef.current.scrollTo({
          left: -cardOffset,
          behavior: "smooth",
        });
      }
    }
  }, 500);

  //Action for Trash Image
  function handleTrashImage() {
    setTakePhoto(null);
    setIsFirstPhoto(false);
    setIsModalOpen(false);
  }

  //Action For Save Image
  const handleSaveFrame = async () => {
    if (!effectRenderer) {
      return;
    }
    effectRenderer.setBrandLogo(brandData.url_logo);
    effectRenderer.setBrandDataHdr(brandData.hdr_environment);
    effectRenderer.setSaveRequest();

    effectRenderer.setTakePicTemplate(takePicTemplate);

    const checkImageReady = (count) => {
      setTimeout(() => {
        if (effectRenderer.image === null) {
          // Increment the count and check again
          checkImageReady(count + 100);
        } else {
          let image = effectRenderer.image;
          setIsModalOpen(true);
          setIsFirstPhoto(true);
          setTakePhoto(image);
          effectRenderer.image = null;
        }
      }, count);
    };
    checkImageReady(100);
  };

  //Action Close Modal
  const handleModalClose = () => {
    if (modalTakePhoto) {
      setIsModalOpen(false);
    }
  };

  //Action Open Modal
  const handleOpenModal = () => {
    if (!modalTakePhoto) {
      setIsModalOpen(true);
    }
  };

  //Action CHange Size
  const handleSelectedSize = (size) => {
    // console.log(size);
    setSelectedSizeCode(size.codiceTaglia);
    const listaModel = size.listaModelli;
    setVtoDataModel(listaModel);
    setSelectedVariant(listaModel[0].urlGlb);
    setSelectedCode(selectedCode);
  };

  //Action Open QRCode
  function openQrCodePopup() {
    setHDOpen(true);
  }

  //Action Close QRCOde
  function closeModalHd() {
    console.log("close");
    setHDOpen(false);
  }

  const videoConstraintsMirror = {
    // aspectRatio: 1.66,
    // width: 1536,
    // height: 1920
    // width: 1109, 
    // height: 1432
    width: 620,
    height: 480
  };

  const videoConstraintsMirrorMediapipe = {
    // aspectRatio: 1.66,
    width: 512,
    height:640
  };

  //Action Return On Mirror Page
  const handleReturnOnPage = () => {
    window.location.href = "/"; // Cambia la pagina corrente con un nuovo URL
  };

  function handleAnimationPlay(){
    if(effectRenderer.isPlayed){
      effectRenderer.reversePlayAnimation();
      setIsClipped(false);
    }else{
      setIsClipped(true);
      effectRenderer.playAnimation();
    }
  }

  return (
    <>
      <div className={styles["App-Mirror"]}>
        <div
          className={`${styles["transition-screen"]} ${
            screenTransaction && !loadingScene ? styles["fade-out"] : ""
          }`}
        >
           <h2>Please wait. We are polishing your lenses</h2>
          <Rings
            visible={true}
            height="200"
            width="200"
            color="rgba(78, 20, 116, 0.7)"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>

         <div className={styles["menu-container-vto"]}>
         <div className={styles["menu-item-vto"]} onClick={handleReturnOnPage}> BACK </div>
      
<div className={styles["menu-item-vto"]}> 
{isAnimated && (
                      <button className={styles['clipped-button']} onClick={handleAnimationPlay}>
                      <FontAwesomeIcon icon={faPlayCircle} className={styles['clipped-icon-button']}/> {!isClipped ? 'APPLY CLIP-ONS' : 'REMOVE CLIP-ONS'}
                    </button>
                    )}
 </div>
<div className={styles["menu-item-vto"]} onClick={openQrCodePopup}> VIEW ON MOBILE </div>
        
        </div>
        

        {HDOpen && (
          <div
            className={styles["modal-backdrop-on-people"]}
            onClick={() => closeModalHd()}
          >
            <ModalQR
              fgColor={"#000000"}
              bgColor={"#ffffff"}
              url={
                "https://www.safilo.webvto.it/" +
                "?c=" +
                selectedBrand.id +
                "&m=" +
                selectedVariant.id
              }
            />
          </div>
        )}

        {glassesData !== null && (
          <>
            <Webcam
              ref={webcamRef}
              // className="input_video"
              //   height={"500px"}
              //  width={"1260px"}
              onUserMediaError={() => console.log("Errore fotocamera")}
              onUserMedia={(stream) =>
                console.log("Accesso fotocamera consentito")
              }
              onLoadedMetadata={handleVideoLoad}
              videoConstraints={videoConstraintsMirror}
              
              style={{
                position: "absolute",
                marginLeft: "0px",
                //  marginRight: "0px",
                transform: "scaleX(-1)",

                left: 0,
                right: 0,
                top: 0,
                zIndex: 1,
                marginBottom: "0px",
                visibility: "hidden",
              }}
            ></Webcam>

              
          <canvas ref={downscaledCanvasRef} style={{ display:"none", transform:"scaleX(-1)"}} width="480" height="620"></canvas>
            <canvas ref={originalCanvasRef} style={{display:"none", transform:"scaleX(-1)"}} width="480" height="620"></canvas> 
             
              {/* <canvas ref={downscaledCanvasRef} style={{ display:"none", transform:"scaleX(-1)"}} width="620" height="480"></canvas>  
            <canvas ref={originalCanvasRef} style={{display:"none", transform:"scaleX(-1)"}} width="620" height="480"></canvas>
         */}
           
            <SliderComponent handleSelectedIndex={handleSelectedIndex} />


            <canvas
              ref={canvasRef}
              className={styles["output_canvas"]}
              style={{
                zIndex: 1,
                width: window.innerWidth,
                height: window.innerHeight,
                transform:"scaleX(-1)"
              }}
            ></canvas>

         
                {modelData && (
                    <>
                  
                    <SaveFrameButton onClick={handleSaveFrame} />
                      
                   
               
                  </>
                )}

        

            {modalTakePhoto && (
              <ModalPhotoShare
                image={imagePhoto}
                closeAction={handleModalClose}
                model={slectedGlassData}
              ></ModalPhotoShare>
            )}
            {/* {isFisrtPhoto && !modalTakePhoto && (
              <>
                <MiniaturePhoto
                  image={imagePhoto}
                  openModalAction={handleOpenModal}
                  trash={handleTrashImage}
                ></MiniaturePhoto>
            )} */}
            <FooterVtoMenu>

            <MirrorVtoMenu  modelData={modelData}
        handleSelectedGlasses={handleSelectedGlasses}
        selectedCode={selectedCode}
        brandData={brandData}
        listLenght={listLenght}
        cardRefs={cardRefs}
        menuRef={menuRef} 
        sizeData={sizeData} 
  selectedSizeCode={selectedSizeCode} 
  handleSelectedSize={handleSelectedSize}
        
        >



        </MirrorVtoMenu>
              <Footer/>
          </FooterVtoMenu>
            
             
          </>
        )}
      </div>
    </>
  );
}

export default MirrorVto;
