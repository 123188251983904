import React, { useContext, useEffect, useState, forwardRef } from 'react';
import styles from './MirrorGlassesCard.module.css';
import { CatalogDataContext } from '../../data/CatalogueDataContext';
import { ImageCacheContext } from '../../data/ImageCacheContext';

const MirrorGlassesCard = forwardRef(({ glasses, onCardClick }, ref) => {

    const { getCatalogById } = useContext(CatalogDataContext);
    const { cache, addToCache } = useContext(ImageCacheContext); // Usa il contesto di cache delle immagini


    const catalog = getCatalogById(glasses.brandId);
    const brandLogo = catalog ? catalog.no_background_logo : 'Marca Sconosciuta'; // Gestisci il caso in cui il catalogo non viene trovato

    const [listOfVariant, setListOfVariant] = useState([])
    const [coverUrl, setCoverUrl] = useState([]);
    const [listOfColors, setColorsList] = useState([]);

    useEffect(() => {
        //Dovrei prendere la size 0 non fare il map
        glasses.listaTaglieOBJ.map((size) => {
            // console.log("Size For Glasses", size);
            let imageUrl = size.listaModelli[0].image3D;
            // if (!cache.includes(imageUrl)) {
            //     addToCache(imageUrl); // Aggiungi all'elenco di cache se non presente
            // }
            setListOfVariant(size.listaModelli);
            setCoverUrl(size.listaModelli[0].image3D); // Usa l'immagine dalla cache se presente
            let listOfColors = []
            size.listaModelli.map((variant) => {
                let color = { first: variant.esaColorFramePrimary, second: variant.esaColorLensesPrimary }
                listOfColors.push(color);
            })
            setColorsList(listOfColors);
            // console.log("Colors", listOfColors)

        })
    }, [glasses])




    return (
        <div className={styles["card-glasses"]} onClick={() => onCardClick(glasses)} ref={ref}>
            <div className={styles["card-glasses-name"]}>
                {glasses.nome_modello}
            </div>
            <div className={styles['container-card-glasses-content']}>

            <div className={styles["container-card-glasses-img"]}>
                <img
                    className={styles["card-glasses-img"]}
                    src={coverUrl}
                    alt={glasses.nome_modello}
                />
            </div>
            <div className={styles.brandcontainerLogo}>
                <img src={brandLogo} className={styles.brandimage} />

            </div>
            </div>
            <div className={styles.colorList}>
                {listOfColors.slice(0, 4).map((color, index) => {
                    return (
                        <div key={index} className={styles.box}>
                            <div className={styles.rectangle} style={{
                                backgroundImage: `linear-gradient(190deg, ${color.first} 0%, 
                ${color.second} 100%)`
                            }} />
                        </div>
                    );
                })}

                {/* <div className={styles.box} style={{backgroundColor:""}}>
                    <div className={styles.rectangle} />
                </div>
                <div className={styles.box}>
                    <div className={styles.rectangle} />
                </div>
                <div className={styles.box}>
                    <div className={styles.rectangle} />
                </div> */}

            </div>




        </div>
    );
});

export default MirrorGlassesCard;
