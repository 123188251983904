import Dexie from 'dexie';

// Creazione del database
const db = new Dexie('Mirror');
db.version(1).stores({
  catalogs: '++id, data',
  glasses: '++id, data',
  selectedVariant: '++id, data',
  selectedBrand: '++id, data',
  selectedGlasses: '++id, data',
  isSessionInCooming: '++id, data',   
  pageStates: '++id, data',
  selectionState: '++id, data',
  filteredList: '++id, data'
})

// Funzione per salvare i dati nel database
export const saveData = async (catalogsData, glassesData) => {
  try {
    // Serializza i dati se sono complessi
    const serializedCatalogs = JSON.stringify(catalogsData);
    const serializedGlasses = JSON.stringify(glassesData);
    // console.log("SAVE")

    await db.catalogs.put({ id: 1, data: serializedCatalogs });
    await db.glasses.put({ id: 1, data: serializedGlasses });
  } catch (error) {
    console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
  }
};

export const saveFilteredListDB = async (filterdList) => {
  try {

    const serializedGlasses = JSON.stringify(filterdList);
    //console.log("SAVE FILTERED LIST");

    await db.filteredList.put({ id: 1, data: serializedGlasses });
  } catch (error) {
    console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
  }
};

export const saveSelectedVariantDB = async (selectedVariant) => {

  try {
    // Serializza i dati se sono complessi
    const serializedVariant = JSON.stringify(selectedVariant);
    // console.log("SAVE VARIANT")

    await db.selectedVariant.put({ id: 1, data: serializedVariant });
  } catch (error) {
    console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
  }
}

export const saveSelectedBrandDB = async (selectedBrand) => {

  try {
    // Serializza i dati se sono complessi
    const serializedBrand = JSON.stringify(selectedBrand);
    // console.log("SAVE BRAND SELECTED")

    await db.selectedBrand.put({ id: 1, data: serializedBrand });
  } catch (error) {
    console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
  }
}

export const saveSelectedGlassesDB = async (selectedGlasses) => {

  try {
    // Serializza i dati se sono complessi
    const serializedGlasses = JSON.stringify(selectedGlasses);
    // console.log("SAVE BRAND GLASSES")

    await db.selectedGlasses.put({ id: 1, data: serializedGlasses });
  } catch (error) {
    console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
  }
}

export const savePageStates = async (isBrandPage, isSingleBrandPage, isSingleGlassesPage, startSelectionLanguage, filteredBygender, filterdeByShapes, isFilteredByType) => {
  try {
    const pageStatesData = {
      isBrandPage,
      isSingleBrandPage,
      isSingleGlassesPage,
      startSelectionLanguage,
      filteredBygender,
      filterdeByShapes,
      isFilteredByType
    };

    await db.pageStates.put({ id: 1, data: JSON.stringify(pageStatesData) });
    // console.log("Stati della pagina salvati" ,pageStatesData );
  } catch (error) {
    console.error("Errore durante il salvataggio degli stati della pagina:", error);
  }
};

export const saveSelectionStates = async (selectedGender, selectedShape, selectedType) => {
  try {
    const selectionStatesData = {
      selectedGender,
      selectedShape,
      selectedType
    };

    await db.selectionState.put({ id: 1, data: JSON.stringify(selectionStatesData) });
    // console.log("Stati di selezione salvati",selectionStatesData );
  } catch (error) {
    console.error("Errore durante il salvataggio degli stati di selezione:", error);
  }
};

// Funzione per cancellare i dati dal database
export const clearData = async () => {
  try {
    await db.selectedVariant.delete(1);
    await db.selectedBrand.delete(1);
    await db.selectedGlasses.delete(1);
    await saveIsSessionCooming(false); // Imposta isSessionInCooming a false

    // console.log("Dati eliminati da IndexedDB");
  } catch (error) {
    console.error("Errore durante l'eliminazione dei dati in IndexedDB:", error);
  }
};

export const saveIsSessionCooming = async (value) => {
  try {
    await db.isSessionInCooming.put({ id: 1, data: value });
    // console.log("isSessionInCooming salvato con valore:", value);
  } catch (error) {
    console.error("Errore durante il salvataggio di isSessionInCooming:", error);
  }
};

// Funzionalità aggiunta per eliminare dati ogni volta ci sia un errore nel caricamento dati
// Funzione per cancellare il database 'Mirror'
const deleteDatabase = async () => {
  try {
    await Dexie.delete('Mirror');
    // console.log("Database 'Mirror' cancellato");
  } catch (error) {
    console.error("Errore durante la cancellazione del database 'Mirror':", error);
  }
};

// Funzione per cancellare l'intero database IndexedDB
const deleteIndexedDB = async () => {
  try {
    await Dexie.delete(); // Elimina l'intero database IndexedDB
    // console.log("IndexedDB cancellato");
  } catch (error) {
    console.error("Errore durante la cancellazione di IndexedDB:", error);
  }
};

// // Esegui la cancellazione del database ogni 20 secondi
// const deleteInterval = 1000 * 1000; // 20 secondi in millisecondi

// setInterval(deleteIndexedDB, deleteInterval);




// ogni 10 secondi si cancella db mirror
// const deleteInterval = 10 * 1000; 
// setInterval(deleteDatabase, deleteInterval);

// Funzione per caricare i dati dal database
export const loadData = async () => {
  try {
    // AGGIUNGERE CONTROLLO SU EVENTUALI ERRORI PER CATTURARLI E RIAVVIARE DB
    const catalogsEntry = await db.catalogs.get(1);
    const glassesEntry = await db.glasses.get(1);
    const selectedVariant = await db.selectedVariant.get(1);
    const selectedBrand = await db.selectedBrand.get(1);
    const selectedGlasses = await db.selectedGlasses.get(1);
    const isNewSession = await db.isSessionInCooming.get(1);
    const filteredList = await db.filteredList.get(1);

    const catalogsData = catalogsEntry ? JSON.parse(catalogsEntry.data) : null;
    const glassesData = glassesEntry ? JSON.parse(glassesEntry.data) : null;
    const selectedVariantData = selectedVariant ? JSON.parse(selectedVariant.data) : null;
    const selectedBrandData = selectedBrand ? JSON.parse(selectedBrand.data) : null;
    const selectedGlassesData = selectedGlasses ? JSON.parse(selectedGlasses.data) : null;
    const isNewSessionData = isNewSession ? JSON.parse(isNewSession.data) : null;
    const filteredListData = filteredList ? JSON.parse(filteredList.data) : null;

    return { catalogsData, glassesData, selectedVariantData, selectedBrandData, selectedGlassesData, isNewSessionData, filteredListData };
  } catch (error) {
    console.error("Errore durante il caricamento dei dati da IndexedDB:", error);
    // Cancellare il database 'Mirror' in caso di errore
    // await deleteDatabase();
    return null;
  }
};

export const loadPageStates = async () => {
  try {
    const pageStatesEntry = await db.pageStates.get(1);
    return pageStatesEntry ? JSON.parse(pageStatesEntry.data) : null;
  } catch (error) {
    console.error("Errore durante il caricamento degli stati della pagina:", error);
    // Cancellare il database 'Mirror' in caso di errore
    // await deleteDatabase();
    return null;
  }
};

export const loadSelectionStates = async () => {
  try {
    const selectionStatesEntry = await db.selectionState.get(1);
    return selectionStatesEntry ? JSON.parse(selectionStatesEntry.data) : null;
  } catch (error) {
    console.error("Errore durante il caricamento degli stati di selezione:", error);
    // Cancellare il database 'Mirror' in caso di errore
    // await deleteDatabase();
    return null;
  }
};

// IndexedDb senza la cancellazione del db mirror in caso di errori

// import Dexie from 'dexie';

// // Creazione del database
// const db = new Dexie('Mirror');
// db.version(1).stores({
//   catalogs: '++id, data',
//   glasses: '++id, data',
//   selectedVariant : '++id, data',
//   selectedBrand : '++id, data',
//   selectedGlasses : '++id, data',
//   isSessionInCooming : '++id, data',
//   pageStates: '++id, data',
//   selectionState: '++id, data',
//   filteredList: '++id, data'
// })

// // Funzione per salvare i dati nel database
// export const saveData = async (catalogsData, glassesData) => {
//     try {
//       // Serializza i dati se sono complessi
//       const serializedCatalogs = JSON.stringify(catalogsData);
//       const serializedGlasses = JSON.stringify(glassesData);
//       // console.log("SAVE")

//       await db.catalogs.put({ id: 1, data: serializedCatalogs });
//       await db.glasses.put({ id: 1, data: serializedGlasses });
//     } catch (error) {
//       console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
//     }
//   };

//   export const saveFilteredListDB = async (filterdList) => {
//     try {

//       const serializedGlasses = JSON.stringify(filterdList);
//       console.log("SAVE FILTERED LIST")

//       await db.filteredList.put({ id: 1, data: serializedGlasses });
//     } catch (error) {
//       console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
//     }
//   };

// export const saveSelectedVariantDB = async (selectedVariant) => {
    
//     try {
//         // Serializza i dati se sono complessi
//         const serializedVariant = JSON.stringify(selectedVariant);
//         // console.log("SAVE VARIANT")
  
//         await db.selectedVariant.put({ id: 1, data: serializedVariant });
//       } catch (error) {
//         console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
//       }
// }
  
// export const saveSelectedBrandDB = async (selectedBrand) => {
    
//   try {
//       // Serializza i dati se sono complessi
//       const serializedBrand = JSON.stringify(selectedBrand);
//       // console.log("SAVE BRAND SELECTED")

//       await db.selectedBrand.put({ id: 1, data: serializedBrand });
//     } catch (error) {
//       console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
//     }
// }

// export const saveSelectedGlassesDB = async (selectedGlasses) => {
    
//   try {
//       // Serializza i dati se sono complessi
//       const serializedGlasses = JSON.stringify(selectedGlasses);
//       // console.log("SAVE BRAND GLASSES")

//       await db.selectedGlasses.put({ id: 1, data: serializedGlasses });
//     } catch (error) {
//       console.error("Errore durante il salvataggio dei dati in IndexedDB:", error);
//     }
// }

// export const savePageStates = async (isBrandPage, isSingleBrandPage, isSingleGlassesPage, startSelectionLanguage, filteredBygender,filterdeByShapes,isFilteredByType) => {
//   try {
//     const pageStatesData = {
//       isBrandPage,
//       isSingleBrandPage,
//       isSingleGlassesPage,
//       startSelectionLanguage,
//       filteredBygender,
//       filterdeByShapes,
//       isFilteredByType
//     };

//     await db.pageStates.put({ id: 1, data: JSON.stringify(pageStatesData) });
//     // console.log("Stati della pagina salvati" ,pageStatesData );
//   } catch (error) {
//     console.error("Errore durante il salvataggio degli stati della pagina:", error);
//   }
// };

// export const saveSelectionStates = async ( selectedGender, selectedShape, selectedType) => {
//   try {
//     const selectionStatesData = {
//       selectedGender,
//       selectedShape,
//       selectedType
//     };

//     await db.selectionState.put({ id: 1, data: JSON.stringify(selectionStatesData) });
//     // console.log("Stati di selezione salvati",selectionStatesData );
//   } catch (error) {
//     console.error("Errore durante il salvataggio degli stati di selezione:", error);
//   }
// };



// // Funzione per cancellare i dati dal database
// export const clearData = async () => {
//   try {
//     await db.selectedVariant.delete(1);
//     await db.selectedBrand.delete(1);
//     await db.selectedGlasses.delete(1);
//     await saveIsSessionCooming(false); // Imposta isSessionInCooming a false

//     // console.log("Dati eliminati da IndexedDB");
//   } catch (error) {
//     console.error("Errore durante l'eliminazione dei dati in IndexedDB:", error);
//   }
// };

// export const saveIsSessionCooming = async (value) => {
//   try {
//     await db.isSessionInCooming.put({ id: 1, data: value });
//     // console.log("isSessionInCooming salvato con valore:", value);
//   } catch (error) {
//     console.error("Errore durante il salvataggio di isSessionInCooming:", error);
//   }
// };

// // Funzione per caricare i dati dal database
// export const loadData = async () => {
//     const catalogsEntry = await db.catalogs.get(1);
//     const glassesEntry = await db.glasses.get(1); 
//     const selectedVariant = await db.selectedVariant.get(1);
//     const selectedBrand = await db.selectedBrand.get(1);
//     const selectedGlasses = await db.selectedGlasses.get(1);
//     const isNewSession = await db.isSessionInCooming.get(1);
//     const filteredList = await db.filteredList.get(1);


//     const catalogsData = catalogsEntry ? JSON.parse(catalogsEntry.data) : null;
//     const glassesData = glassesEntry ? JSON.parse(glassesEntry.data) : null;
//     const selectedVariantData = selectedVariant ? JSON.parse(selectedVariant.data) : null;
//     const selectedBrandData = selectedBrand ? JSON.parse(selectedBrand.data) : null;
//     const selectedGlassesData = selectedGlasses ? JSON.parse(selectedGlasses.data) : null;
//     const isNewSessionData = isNewSession ? JSON.parse(isNewSession.data) : null;
//     const filteredListData = filteredList ? JSON.parse(filteredList.data) : null;

//     return { catalogsData, glassesData,selectedVariantData ,selectedBrandData,selectedGlassesData,isNewSessionData, filteredListData};
//   };
  
//   export const loadPageStates = async () => {
//     try {
//       const pageStatesEntry = await db.pageStates.get(1);
//       return pageStatesEntry ? JSON.parse(pageStatesEntry.data) : null;
//     } catch (error) {
//       console.error("Errore durante il caricamento degli stati della pagina:", error);
//       return null;
//     }
//   };
  
//   export const loadSelectionStates = async () => {
//     try {
//       const selectionStatesEntry = await db.selectionState.get(1);
//       return selectionStatesEntry ? JSON.parse(selectionStatesEntry.data) : null;
//     } catch (error) {
//       console.error("Errore durante il caricamento degli stati di selezione:", error);
//       return null;
//     }
//   };
  