import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState} from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Routes } from "react-router-dom";
import { whitelist } from './Utils/constant';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { isMobile } from "react-device-detect";
import './Utils/LanguageUtils/i18n';
import LocalStorageManager from './data/LocalStorageManager';
import RouterQuery from './pages/RouterQuery'
import { CatalogDataContext, CatalogDataProvider } from './data/CatalogueDataContext';
import MirrorVto from './pages/MirrorVto';
import { ImageCacheProvider } from './data/ImageCacheContext';
import { StatusBar } from 'react-native-web';

function App() {

  const MAX_LOCAL_STORAGE_SIZE = 6000 * 1024 * 1024;

  return (
    <div >
      <StatusBar hidden={true} />

      {/* <CatalogDataProvider c={["qmDTd8ciaSs31akT3Eqq", "rBf3geYISXtkue0WGgFT", "UPgshXfGThBLfqZyrcxZ", "USamwu7Ywd2oNAU5eL50", "Nqyd5EKyrBb3fufpJyKS","rxnBLnp736gqjFBNLfdS"]}> */}
      <CatalogDataProvider c={["qmDTd8ciaSs31akT3Eqq", "rBf3geYISXtkue0WGgFT","UPgshXfGThBLfqZyrcxZ","4IWjEk506qLdof6Pg0Kz"]}>
      {/* <LocalStorageManager maxSize={MAX_LOCAL_STORAGE_SIZE} /> */}
      <ImageCacheProvider>

      <BrowserRouter basename={`/`}>
        <Routes>
        <Route path="/" element={<RouterQuery></RouterQuery>}/>
        <Route path="/vto" element={<MirrorVto></MirrorVto>}/> 
        </Routes>
           
           
      </BrowserRouter>
      </ImageCacheProvider>

      </CatalogDataProvider>
    </div>
  );
}

export default App;
